import { LoginContext } from '@/context/login'
import { useContext, useState } from 'react'
import Alert from '../ui/Alert'
import Label from '../ui/Label'
import ActionButton from '../ui/ActionButton'
import {
	faExclamationCircle,
	faPaperPlane,
	faRedoAlt,
} from '@fortawesome/pro-duotone-svg-icons'
import { faFingerprint } from '@fortawesome/pro-solid-svg-icons'
import AuthService from '@/services/auth'
import InputDigits from '../ui/InputDigits'
import Button from '../ui/Button'
import AuthProcess from '@/components/auth/AuthProcess'
import Sleep from '@/lib/sleep'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export default function AuthCode() {
	const [errorMessage, setErrorMessage] = useState('Error')
	const [error, setError] = useState(null)
	const { executeRecaptcha } = useGoogleReCaptcha()
	const statusList = {
		default: {
			type: 'default',
			icon: faRedoAlt,
			message: 'Resend auth code',
		},
		error: {
			type: 'error',
			icon: faExclamationCircle,
			message: errorMessage,
		},
		success: {
			type: 'success',
			icon: faPaperPlane,
			message: 'Email sent',
		},
	}
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [loading, setLoading] = useState(false)
	const [resendLoading, setResendLoading] = useState(false)
	const [status, setStatus] = useState(statusList['default'])
	const [authCode, setAuthCode] = useState('')

	function resetAction() {
		setStatus(statusList['default'])
	}

	async function resendCode() {
		setResendLoading(true)
		setError(null)
		const body = {
			email: loginState.auth.email,
			type: loginState.auth.account_type,
		}

		const recaptchaToken = await executeRecaptcha('login_page')

		const config = {
			headers: {
				Recaptcha: recaptchaToken,
			},
		}
		await Sleep(1000)

		await AuthService.Init(body, config)
			.then(() => {
				setResendLoading(false)
				setStatus(statusList['success'])
			})
			.catch((err) => {
				setResendLoading(false)
				setErrorMessage(err.message)
				setStatus(statusList['error'])
			})
		return () => { }
	}

	async function Login() {
		const body = {
			email: loginState.auth.email,
			code: authCode,
			type: loginState.auth.account_type,
		}

		setLoading(true)

		const recaptchaToken = await executeRecaptcha('login_page')

		const config = {
			headers: {
				Recaptcha: recaptchaToken,
			},
		}

		await Sleep(500)

		await AuthService.Login(body, config)
			.then((data) => {
				console.log('data')
				console.log(data)
				setLoading(false)
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							email: data.email,
							firstname: data.firstname,
							lastname: data.lastname,
							profile_pic: data.profile_pic,
							account_type: data.account_type,
							session_id: data.session_id,
							iam: data.iam,
							wealth: data.steps.wealth,
							investment: data.steps.investment,
							kyc: data.steps.kyc,
						},
						currentComponent: <AuthProcess></AuthProcess>,
					},
				})
			})
			.catch((err) => {
				setAuthCode('')
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	return (
		<>
			<div>
				{error ? (
					<Alert type="error" title={error}></Alert>
				) : (
					<Alert
						type="email"
						title="Check your inbox"
						description={'Auth code has been sent to your email'}></Alert>
				)}
				<div className="flex justify-between">
					<div>
						<Label value="Auth Code"></Label>
					</div>
					<div>
						<Label font="light" value={loginState.auth?.email.length > 24 ? loginState.auth?.email.slice(0, 24) + '...' : loginState.auth?.email}></Label>
						{!loading ? (
							<ActionButton
								status={status}
								onClick={resendCode}
								onFinish={resetAction}
								loading={resendLoading}></ActionButton>
						) : null}
					</div>
				</div>
			</div>
			<div className="mt-3">
				<InputDigits value={[authCode, setAuthCode]} />
			</div>
			<div className="mt-4">
				<Button
					onClick={Login}
					full
					icon={faFingerprint}
					disabled={authCode.trim().length != 6}
					loading={loading}
					label="Authenticate"
				/>
			</div>
		</>
	)
}
